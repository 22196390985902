if (document.querySelectorAll("[data-modal-target]")) {
  const openModalButtons = document.querySelectorAll("[data-modal-target]");
  const closeModalButtons = document.querySelectorAll("[data-close-button]");
  const overlay = document.getElementById("overlay");

  const back = document.getElementById("return");

  const option1 = document.getElementById("op1");
  const option2 = document.getElementById("op2");
  const option3 = document.getElementById("op3");

  const modalInitial = document.getElementById("modalInitial");
  const modalEmail = document.getElementById("modalEmail");
  const modalPassword = document.getElementById("modalPassword");
  const modalOther = document.getElementById("modalOther");

  openModalButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const modal = document.querySelector(button.dataset.modalTarget);
      openModal(modal);
    });
  });

  overlay.addEventListener("click", () => {
    const modals = document.querySelectorAll(".modal.active");
    modals.forEach((modal) => {
      closeModal(modal);
    });
  });

  closeModalButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const modal = button.closest(".modal");
      closeModal(modal);
    });
  });

  function openModal(modal) {
    if (modal == null) return;
    modal.classList.add("active");
    overlay.classList.add("active");
  }

  function closeModal(modal) {
    if (modal == null) return;
    modal.classList.remove("active");
    overlay.classList.remove("active");
  }

  back.addEventListener("click", function () {
    modalInitial.style.display = "block";
    modalEmail.style.display = "none";
    modalPassword.style.display = "none";
    modalOther.style.display = "none";
  });

  option1.addEventListener("click", function () {
    modalInitial.style.display = "none";
    modalEmail.style.display = "block";
    modalEmail.style.height = "auto";
  });
  option2.addEventListener("click", function () {
    modalInitial.style.display = "none";
    modalPassword.style.display = "block";
    modalPassword.style.height = "auto";
  });
  option3.addEventListener("click", function () {
    modalInitial.style.display = "none";
    modalOther.style.display = "block";
    modalOther.style.height = "auto";
  });
}
