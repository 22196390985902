//<!--Input error = border-red-100 border-2 drop-shadow-red-shadow  -->
const inputs = document.querySelectorAll(".input");
const errorText = document.querySelector(".text-error");

if (!!errorText) {
  inputs.forEach((input) => {
    input.classList.add("input-error");
  });
}

if (document.querySelector("#cpfInput")) {
  IMask(document.getElementById("cpfInput"), {
    mask: [
      {
        mask: "000.000.000-00",
        maxLength: 14,
      },
    ],
  });
}
