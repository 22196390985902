//** EYE TOGGLE VISUALIZAR SENHA*/

if (document.querySelector("#password")) {
  const password = document.querySelector("#password");
  const passwordConfirm = document.querySelector("#passwordConfirm");
  const togglePassword = document.querySelector("#toggle-password");
  const sendButton = document.querySelector("#sendButton");
  const fullname = document.querySelector("#name").textContent;
  const fullnameSplitted = fullname.split(" ");

  sendButton.disabled = true;
  sendButton.style.cursor = "not-allowed";

  togglePassword.addEventListener("click", function () {
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);

    if (this.classList.contains("fa-eye")) {
      this.classList.remove("fa-eye");
      this.classList.add("fa-eye-slash");
    } else {
      this.classList.add("fa-eye");
      this.classList.remove("fa-eye-slash");
    }
  });

  // ETAPAS SENHA FORTE

  let lowUpperCase = document.querySelector(".low-upper-case");
  let eightCharacters = document.querySelector(".eight-character");
  let oneNumber = document.querySelector(".one-number");
  let namePart = document.querySelector(".name-part");
  let specialChar = document.querySelector(".one-special-char");
  let equalPasswords = document.querySelector(".equal-password");

  password.addEventListener("input", function () {
    passwordSteps(password.value, passwordConfirm.value);
  });
  passwordConfirm.addEventListener("input", function () {
    passwordSteps(password.value, passwordConfirm.value);
  });

  function passwordSteps(password, passwordConfirmation) {
    let count = 0;

    if (password.trim().length > 0 || passwordConfirmation.trim().length > 0) {
      //Senhas iguais
      const isPasswordsEqual = passwordConfirmation === password;

      if (isPasswordsEqual) {
        equalPasswords.classList.remove("fa-circle");
        equalPasswords.classList.add("fa-solid", "fa-check");
      } else {
        equalPasswords.classList.remove("fa-solid", "fa-check");
        equalPasswords.classList.add("fa-regular", "fa-circle");
      }

      //8 caracteres
      if (password.length > 7) {
        count += 1;
        eightCharacters.classList.remove("fa-circle");
        eightCharacters.classList.add("fa-solid", "fa-check");
      } else {
        eightCharacters.classList.remove("fa-solid", "fa-check");
        eightCharacters.classList.add("fa-regular", "fa-circle");
      }

      //Letra minuscula e maiuscula
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        count += 1;
        lowUpperCase.classList.remove("fa-circle");
        lowUpperCase.classList.add("fa-solid", "fa-check");
      } else {
        lowUpperCase.classList.remove("fa-solid", "fa-check");
        lowUpperCase.classList.add("fa-regular", "fa-circle");
      }

      //Um numero
      if (password.match(/([0-9])/)) {
        count += 1;
        oneNumber.classList.remove("fa-circle");
        oneNumber.classList.add("fa-solid", "fa-check");
      } else {
        oneNumber.classList.remove("fa-solid", "fa-check");
        oneNumber.classList.add("fa-regular", "fa-circle");
      }

      //Caractere especial
      if (password.match(/([!%&@#$^*?_~,])/)) {
        count += 1;
        specialChar.classList.remove("fa-circle");
        specialChar.classList.add("fa-solid", "fa-check");
      } else {
        specialChar.classList.remove("fa-solid", "fa-check");
        specialChar.classList.add("fa-regular", "fa-circle");
      }

      //Nome do usuario
      const hasNameInPassword = verifyHasNamePartInPassword(password, fullnameSplitted);

      if (hasNameInPassword) {
        namePart.classList.remove("fa-solid", "fa-check");
        namePart.classList.add("fa-regular", "fa-circle");
      } else {
        namePart.classList.remove("fa-circle");
        namePart.classList.add("fa-solid", "fa-check");
      }

      //Contador
      if (count === 4 && isPasswordsEqual && !hasNameInPassword) {
        sendButton.disabled = false;
        sendButton.style.cursor = "pointer";
      } else {
        sendButton.disabled = true;
        sendButton.style.cursor = "not-allowed";
      }
    } else {
      const elementsToReset = [equalPasswords, eightCharacters, lowUpperCase, oneNumber, specialChar, namePart];
      resetValidationFeedback(elementsToReset);
    }
  }

  function verifyHasNamePartInPassword(password, fullnameSplitted) {
    for (let name of fullnameSplitted) {
      const namePartSearcherRegex = new RegExp(`${name}`, "i");

      const hasNamePartInPassword = namePartSearcherRegex.test(password);

      if (hasNamePartInPassword) return true;
    }

    return false;
  }

  function resetValidationFeedback(elementsToReset) {
    sendButton.disabled = true;
    sendButton.style.cursor = "not-allowed";

    elementsToReset.forEach(element => {
      element.classList.remove("fa-solid", "fa-check");
      element.classList.add("fa-regular", "fa-circle");
    })
  }
}
